<template>
  <div>
    <HeaderPreviousEditions title="Edició 2023">
      <a
        href="https://hikeandfly.puntxat.cat/admin/storage/hf_bergueda_normativa_2023.pdf"
        class="navbar-item"
        >Normativa</a
      >
    </HeaderPreviousEditions>
    <Ranking ref="ranking" v-bind:showCombosScore="false" />
  </div>
</template>

<script>
import HeaderPreviousEditions from "../components/HeaderPreviousEditions.vue";
import Ranking from "../components/RankingLegacy/Ranking.vue";
import results from "./ranking-2022.json";

export default {
  components: { Ranking, HeaderPreviousEditions },
  name: "Edition2022",
  RESULTS2022: results,
  mounted() {
    this.$refs.ranking.loadData(this.$options.RESULTS2022.data);
    this.$root.$emit("ready");
  },
};
</script>
